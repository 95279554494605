<template>
  <div class="app-staff">
    <div class="reviews">
      <div class="reviews-items">
        <slot></slot>
      </div>
    </div>
    <div class="minies">
      <div class="reviews1">
        <div class="minies-wrapper">
          <div class="mini-items" v-if="minies">
            <div
              class="mini-item"
              @click="
                current = index
                setActive(index)
              "
              :class="[{ active: index === current }]"
              v-for="(item, index) in minies"
              :key="index"
            >
              <div class="mini-photo">
                <img :src="item.src" alt="" />
              </div>
              <div class="mini-data">
                <div class="mini-name">{{ item.name }}</div>
                <div class="mini-who">{{ item.who }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      length: 0,
      current: 0,
      slides: [],
      // minies: [],
    }
  },
  mounted() {
    this.slides = [...this.$el.querySelectorAll('.reviews-items > *')]
    const length = this.slides.length
    this.length = length
    this.setActive()
  },
  computed: {
    minies() {
      const minies = this.slides.map((slide) => {
        const logo = slide.querySelector('.person')?.src
        const name = slide.querySelector('.name span')

        const who = slide.querySelector('.who')
        return { src: logo, name: name ? name.innerText : '', who: who ? who.innerText : '' }
      })
      return minies
    },
  },
  methods: {
    setActive(index = 0) {
      // slides
      this.slides.forEach((slide, i) => {
        if (i !== index) {
          slide.classList.remove('active')
        } else {
          slide.classList.add('active')
        }
      })
      // minies
      const images = [...this.$el.querySelectorAll('.mini-items > *')]
      images.forEach((image, i) => {
        if (i !== index) {
          image.classList.remove('active')
        } else {
          image.classList.add('active')
        }
      })
    },
    next() {
      if (this.current !== this.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
      this.setActive(this.current)
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.length - 1
      }
      this.setActive(this.current)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-staff {
  color: #fff;
}
.reviews {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  overflow: hidden;
}
.wrapper {
  position: relative;
}
.reviews-items {
  & > * {
    grid-template-columns: 1fr 1.3fr;
    display: none;
    &.active {
      display: grid;
    }
    & > * {
      &:nth-child(1) {
        position: relative;
        img {
          position: absolute;
          left: 0%;
          top: 0%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &:nth-child(2) {
        padding: 2em;
      }
    }
  }
  .name,
  .who {
    font-size: 1.25em;
    margin-bottom: 0.5em;
    b {
      display: block;
    }
  }
  .about {
    a {
      font-size: 0.875em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:after {
        content: '»';
      }
    }
    img {
      filter: invert(100%);
      margin-top: -2em;
    }
  }
}
.text {
  margin-top: 1em;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  width: 100%;
}
.minies {
  margin-top: 1.5em;
}
.minies-wrapper {
  position: relative;
}
.mini-items {
  display: flex;
  & > * {
    overflow: hidden;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    position: relative;
    transition: 0.3s;
    background: rgba(54, 54, 54, 0.2);
    border-radius: 0px;
    cursor: pointer;
    &:hover {
      background: rgba(54, 54, 54, 0.3);
    }
    &:not(:last-child) {
      margin-right: 4px;
    }
    &.active {
      background: rgba(54, 54, 54, 0.5);
    }
    .mini-who {
      margin-top: 1.4em;
    }
    .mini-photo {
      position: relative;
    }
    .mini-data {
      padding: 0.5em;
      font-size: 0.875em;
      span {
        display: none;
      }
    }
    img {
      position: absolute;
      left: 0%;
      top: 0%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 767px) {
  .reviews-items {
    & > * {
      &.active {
        display: block;
        img {
          position: relative;
        }
      }
    }
  }
  .mini-items {
    & > * {
      display: block;
      img {
        display: none;
      }
    }
  }
}
</style>
