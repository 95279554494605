<template>
  <div class="app-service-delivery">
    <div class="gallery">
      <slot name="title"></slot>
      <slot name="desc"></slot>
      <div class="subtitle-help">
        <slot name="subtitle"></slot>
        <slot name="help"></slot>
      </div>
      <div class="services">
        <slot></slot>
      </div>
      <!--  -->
      <div class="button-wrapper">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      length: 0,
      current: 0,
    }
  },
  mounted() {
    const length = this.$el.querySelectorAll('.images > *').length
    this.length = length
    this.setActive()
  },
  methods: {
    setActive(index = 0) {
      const images = [...this.$el.querySelectorAll('.mini-items > *')]
      images.forEach((image, i) => {
        if (i !== index) {
          image.classList.remove('active')
        } else {
          image.classList.add('active')
        }
      })
    },
    next() {
      if (this.current !== this.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
      this.setActive(this.current)
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.length - 1
      }
      this.setActive(this.current)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-service-delivery {
  color: #fff;
}
.gallery {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  padding: 2em;
  .gallery {
  }
}
.wrapper {
  position: relative;
}

.services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em 7em;
  margin-top: 1em;
  & > * {
    position: relative;
    // item
    & > div {
      &:nth-child(1) {
        background: rgba(54, 54, 54, 0.2);
        border-radius: 10px;
        padding: 0.3em;
        display: inline-flex;
        width: 8em;
        height: 6em;
        align-items: center;
        justify-content: center;
      }
      &:nth-child(2) {
        font-size: 0.9375em;
        margin-top: 1em;
      }
    }
    &:nth-child(2n + 1) {
      &:after {
        content: '';
        width: 80px;
        height: 30px;
        position: absolute;
        left: calc(100% - 2em);
        top: 1.5em;
        background: url('../../assets/img/arrow.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    span {
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      border-radius: 50%;
      position: absolute;
      left: 10em;
      top: 0;
      border: solid 1px;
    }
  }
}
.subtitle-help {
  margin-top: 1em;
}
button {
  font-size: 1em;
  height: 30px;
  font-family: inherit;
  background: none;
  border-radius: 5px;
  border: solid 1px;
  color: #fff;
  margin-top: 1em;
  grid-column: 2/3;
}
.button-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em 7em;
  border-top: dashed 2px;
  margin-top: 1em;
  padding-top: 0.5em;
}
@media screen and (max-width: 767px) {
  .gallery {
    padding: 15px;
  }
  .buttons {
    & > * {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
}
</style>
