<template>
  <div class="app-gallery">
    <div class="gallery">
      <slot name="title"></slot>
      <slot name="howto"></slot>
    </div>
    <!--  -->
    <div class="services">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      length: 0,
      current: 0,
    }
  },
  mounted() {
    const length = this.$el.querySelectorAll('.images > *').length
    this.length = length
    this.setActive()
  },
  methods: {
    setActive(index = 0) {
      const images = [...this.$el.querySelectorAll('.mini-items > *')]
      images.forEach((image, i) => {
        if (i !== index) {
          image.classList.remove('active')
        } else {
          image.classList.add('active')
        }
      })
    },
    next() {
      if (this.current !== this.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
      this.setActive(this.current)
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.length - 1
      }
      this.setActive(this.current)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-gallery {
  color: #fff;
}
.gallery {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  padding: 2em;
  .gallery {
  }
}
.wrapper {
  position: relative;
}
.images {
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  transition: 0.3s;
  & > * {
    position: relative;
    padding-bottom: 70%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.images-wrapper {
  overflow: hidden;
  position: relative;
}
.text {
  margin-top: 1em;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  width: 100%;
}
.minies {
  margin-top: 1.5em;
}
.minies-wrapper {
  position: relative;
}
.mini-items {
  display: flex;
  & > * {
    flex-grow: 1;
    position: relative;
    padding-bottom: 5em;
    filter: grayscale(100%);
    transition: 0.3s;
    &:not(:last-child) {
      margin-right: 0.3em;
    }
    &.active {
      filter: none;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  margin-top: 1em;
  & > * {
    display: flex;
    flex-direction: column;
    & > div {
      background: rgba(54, 54, 54, 0.2);
      border-radius: 10px;
      padding: 0.3em;
      text-align: center;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      margin-bottom: 1em;
      height: 4em;
    }
    span {
      display: block;
      margin-top: auto;
    }
    button {
      font-size: 1em;
      height: 30px;
      font-family: inherit;
      background: none;
      border-radius: 5px;
      border: solid 1px;
      color: #fff;
      width: 100%;
      margin-top: 1em;
    }
  }
}
@media screen and (max-width: 767px) {
  .gallery {
    padding: 15px;
  }
  .buttons {
    & > * {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
}
</style>
