<template>
  <div class="app-calc">
    <div class="items">
      <layout-calc-item v-for="(item, index) in calc" :key="index" :item="item"></layout-calc-item>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    layoutCalcItem: () => import('@/components/layout/layout-calc-item.vue'),
  },
  data() {
    return {
      calc: [
        {
          name: '*Моментальная отправка',
          pic: 'calc1.png',
          price: 4000,
          add: 60,
        },
        {
          name: '*Ожидание дозагрузки',
          pic: 'calc2.png',
          price: 3500,
          add: 60,
        },
        // {
        //   name: '*Ожидание дозагрузки',
        //   pic: 'calc3.png',
        //   price: 3000,
        //   add: 60,
        // },
        {
          name: '*Ожидание дозагрузки',
          pic: 'calc4.png',
          price: 9000,
          add: 100,
        },
      ],
    }
  },
  async mounted() {
    await this.loadAPI()
  },
  methods: {
    loadAPI() {
      return new Promise((resolve, reject) => {
        if (window.ymaps) {
          console.log('Yandex Api уже загружено')
          resolve()
        } else {
          console.log('Загружаю Yandex Api...')
          const script = document.createElement('script')
          script.src = 'https://api-maps.yandex.ru/2.1/?apikey=9ad9f2c6-f25e-43ea-a721-30c12e741c19&lang=ru_RU'
          script.onload = () => {
            console.log('Yandex Api Ready')
            resolve()
          }
          document.body.appendChild(script)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.app-calc {
}
</style>
