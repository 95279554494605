<template>
  <div class="layout-page">
    <div class="container main-container">
      <div class="row header align-start flex-xs-wrap">
        <div class="col-2 xs-hide no-padd stick logo-stick">
          <img class="logo-side" src="@/assets/img/logo.png" alt="" />
        </div>
        <div class="col-4 col-xs-12 text-left stick">
          <slot name="logo"></slot>
          <div class="menu xs-hide">
            <slot name="menu"></slot>
          </div>
          <div class="mobile-buttons">
            <a href="tel:+7 (925) 620-03-50"><img src="@/assets/img/phone.svg" alt="" /></a>
            <a href="/"><img src="@/assets/img/small-calc.png" alt="" /></a>
            <img
              @click="menuOpened = !menuOpened"
              class="menu-button"
              :src="`@/assets/img/icons/${menuOpened ? 'close' : 'menu'}.svg`"
              alt=""
            />
          </div>
        </div>
        <div class="col-8 col-xs-12 text-left">
          <div class="calc-wrapper">
            <slot name="calc"></slot>
          </div>
          <div class="content"><slot></slot></div>
        </div>
      </div>
    </div>
    <!--  -->
    <slot name="footer"></slot>
    <!--  -->
    <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
      <div v-if="menuOpened" class="mobile-menu abs">
        <slot name="menu"></slot>
      </div>
    </transition>
    <!--  -->
    <layout-popup></layout-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpened: false,
    }
  },
  mounted() {
    const menu = this.$el.querySelectorAll('.menu .menu-item-has-children')
    for (const link of menu) {
      const a = link.querySelector('a')
      a.removeAttribute('href')
    }
  },
}
</script>

<style lang="scss" scoped>
.layout-page {
  position: relative;
  color: #fff;
}
.logo-stick {
  mix-blend-mode: multiply;
}
</style>

<style lang="scss">
.layout-page {
  .calc-wrapper {
    text-align: right;
    padding-top: 3.5em;
    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        mix-blend-mode: multiply;
        margin-left: 1em;
      }
    }
  }
  .main-container {
    .menu {
      margin-top: 3em;
      .menu-item-has-children {
        cursor: pointer;
        &:hover {
          ul {
            display: block !important;
          }
        }
      }
      li {
        list-style: none;
        &.current-menu-parent {
          ul {
            display: block;
          }
        }
        ul {
          margin: 1em 0;
          display: none;
        }
        li {
          padding-left: 1em;
          &.current-menu-item {
            a {
              border-left: solid 3px;
              padding-left: 0.7em;
              font-weight: 600;
            }
          }
          a {
            font-size: 1em;
            text-transform: uppercase;
            padding: 0.7em 0;
            display: block;
            position: relative;
          }
        }
        a {
          font-size: 2em;
          text-transform: uppercase;
          padding: 0.15em 0;
          display: block;
          &.active {
            font-weight: 600;
          }
        }
      }
    }
    .content {
      margin-top: 1em;
      min-height: 60vh;
    }
  }
}
.stick {
  position: sticky;
  top: 0;
}
.mobile-buttons {
  display: none;
}
.mobile-menu {
  position: fixed;
  z-index: 100;
  background: #4a789c;
  color: #fff;
  padding: 15px;
  li {
    list-style: none;
    ul {
      padding-left: 1.5em;
      a {
        font-size: 1.3em;
      }
    }
  }
  a {
    font-size: 2em;
    text-transform: uppercase;
    margin: 0.5em 0;
    display: block;
    &.active {
      font-weight: 700;
    }
  }
}
@media screen and (max-width: 767px) {
  .mobile-buttons {
    display: flex;
    align-self: center;
    position: absolute;
    right: 15px;
    top: 10px;
    & > * {
      margin-left: 1em;
    }
    img {
      height: 2em;
    }
  }
  .stick {
    z-index: 200;
    background: #4a789c;
  }
  .logo {
    padding-top: 0em;
    width: 7em;
  }
  .content {
    margin-top: 0em !important;
  }
  .close {
    top: 30px;
  }
  .calc-wrapper {
    display: none;
  }
  .mobile-menu {
    padding-top: 3em;
  }
}
</style>
