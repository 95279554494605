<template>
  <div class="ui-arrows abs">
    <div @click="$emit('prev')" class="arrow">
      <img src="../../assets/img/icons/arrow-left.svg" alt="" />
    </div>
    <div @click="$emit('next')" class="arrow">
      <img src="../../assets/img/icons/arrow-right.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ui-arrows {
  --gap: 2em;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + var(--gap) * 2);
  left: calc(var(--gap) * -1);
  z-index: 3;
}
.arrow {
  pointer-events: all;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 1em;
    height: 1em;
  }
}
</style>
