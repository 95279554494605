import "@/assets/css/animate.css";
// Подключаем глобально стили
import "@/assets/css/kolbins-grid.css";
import "@/assets/css/style.scss";
import axios from "@/plugins/axios";
import bus from "@/plugins/bus";
// Подключаем глобально компоненты
import components from "@/plugins/components"; // Отключаем предупреждение о режиме разработки
import store from "@/store/index"; // Импортируем Store
import Vue from "vue"; // Импортируем Vue

components(Vue);

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

// Подключаем Axios
Vue.prototype.$axios = axios;

// Создаем event bus
Vue.prototype.$bus = bus;

// Вшиваем в this Store
Vue.prototype.$store = store;

export default Vue;
