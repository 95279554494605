<template>
  <div class="app-example">
    <div class="wrapper">
      <div class="grid">
        <slot></slot>
      </div>
      <slot name="button-wrapper"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-example {
}
.wrapper {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  padding: 2em;
}
.grid {
  & > * {
    &:nth-child(1) {
      margin-bottom: 1em;
      padding-bottom: 1em;
      border-bottom: solid 2px;
    }
    & > div {
      &:nth-child(2) {
        margin-top: 1em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 4em;
        .price {
          background: #fff;
          border-radius: 40px;
          padding: 0.2em 0.7em;
          color: #000;
          display: inline-block;
          margin-top: 0.5em;
        }
      }
    }
  }
}
.button-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1em;
  img {
    margin-right: 1em;
  }
}

@media screen and (max-width: 767px) {
  .grid {
    & > * {
      & > div {
        &:nth-child(2) {
          grid-template-columns: repeat(3, 1fr);
          img {
            display: none;
          }
          .price {
            padding: 0.2em 0.5em;
          }
        }
      }
    }
  }
}
</style>
