<template>
  <div class="app-features">
    <slot name="title"></slot>
    <div class="features">
      <div class="wrapper" :class="[{ padding }, { leftAlign }]">
        <slot name="inner-title"></slot>
        <div class="features-items">
          <slot></slot>
        </div>
        <div v-if="$slots.bottom" class="bottom">
          <slot name="bottom"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: Boolean,
      default() {
        return false
      },
    },
    leftAlign: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.app-features {
  color: #fff;
  .middle-title {
    margin-bottom: 0.5em;
  }
}
.features {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  overflow: hidden;
}
.wrapper {
  position: relative;
  text-align: center;
  &.padding {
    padding: 2em;
  }
  &.leftAlign {
    text-align: left;
    .features-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1em;
      justify-content: flex-start;
      & > * {
        padding: 0;
      }
    }
  }
}
.features-items {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  padding: 0em;
  & > * {
    flex-basis: 25%;
    padding: 1em;
    div {
      font-size: 0.875em;
      margin-top: 1em;
    }
  }
}
.bottom {
  margin-top: 1em;
  padding-top: 1em;
  border-top: solid 3px;
}
@media screen and (max-width: 767px) {
  .features-items {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    // & > * {
    //   flex-basis: 50%;
    // }
  }
}
</style>
