<template>
  <div class="app-for">
    <div v-if="$slots.title" class="title">
      <slot name="title"></slot>
    </div>
    <div class="grid">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-for {
}
.title {
  margin-bottom: 2em;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  & > * {
    &:nth-child(1) {
      border-right: solid 1px;
      padding-right: 2em;
    }
    &:nth-child(2) {
      padding-left: 2em;
    }
    //
    & > div {
      display: flex;
      align-items: center;
      img {
        margin-right: 1em;
      }
      div {
        color: #000;
      }
    }
    ul {
      margin-top: 2em;
      padding-left: 1.5em;
      li {
        font-size: 0.9375em;
        list-style: none;
        position: relative;
        margin-bottom: 1em;
        color: #000;
        &:before {
          content: '';
          background: var(--main);
          width: 8px;
          height: 8px;
          left: -1.5em;
          top: 3px;
          position: absolute;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .grid {
    display: block;
    & > * {
      &:nth-child(1) {
        padding-right: 0;
        border-right: none;
      }
      &:nth-child(2) {
        padding-left: 0;
        margin-top: 2em;
      }
    }
  }
}
</style>
