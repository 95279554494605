<template>
  <div class="app-mini-gallery">
    <div class="minies">
      <div class="gallery">
        <div class="minies-wrapper">
          <div class="mini-items">
            <slot></slot>
          </div>
          <ui-arrows @next="next" @prev="prev"></ui-arrows>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      length: 0,
      current: 0,
    };
  },
  mounted() {
    const length = this.$el.querySelectorAll(".mini-items > *").length;
    this.length = length;
    this.setActive();
  },
  methods: {
    setActive(index = 0) {
      const images = [...this.$el.querySelectorAll(".mini-items > *")];
      images.forEach((image, i) => {
        if (i !== index) {
          image.classList.remove("active");
        } else {
          image.classList.add("active");
        }
      });
    },
    next() {
      if (this.current !== this.length - 1) {
        this.current++;
      } else {
        this.current = 0;
      }
      this.setActive(this.current);
    },
    prev() {
      if (this.current !== 0) {
        this.current--;
      } else {
        this.current = this.length - 1;
      }
      this.setActive(this.current);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-mini-gallery {
  color: #fff;
}
.gallery {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  padding: 2em 3em;
}
.minies {
  margin-top: 1.5em;
}
.minies-wrapper {
  position: relative;
}
.mini-items {
  display: flex;
  & > * {
    flex-grow: 1;
    position: relative;
    padding-bottom: 5em;
    filter: grayscale(100%);
    transition: 0.3s;
    &:not(:last-child) {
      margin-right: 0.3em;
    }
    &.active {
      filter: none;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
