<template>
  <div class="app-blog">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-blog {
  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    & > * {
      display: block;
      &:nth-child(1) {
        grid-column: span 3;
        .date-title {
          display: flex;
          .title {
            font-weight: 600;
            text-transform: uppercase;
            padding-top: 0.5em;
          }
        }
        img {
          border-radius: 10px;
          margin-top: 1em;
        }
        .desc {
          font-size: 0.9375em;
          margin-top: 1em;
        }
        .button {
          width: 100%;
          text-align: right;
          opacity: 0.5;
          margin-top: 1em;
          cursor: pointer;
          display: block;
        }
      }
    }
  }
  .post {
    background: rgba(54, 54, 54, 0.2);
    border-radius: 10px;
    padding: 2em 2em;
    position: relative;
    &:not(:first-child) {
      img,
      .desc,
      .button {
        display: none;
      }
      .title {
        font-size: 0.875em;
      }
    }
    .date {
      padding-top: 8px;
      width: 90px;
      height: 50px;
      font-size: 15px;
      text-align: center;
      background: url('../../assets/img/date.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
  }
}
@media screen and (max-width: 767px) {
  .app-blog {
    .content {
      display: block;
      .post {
        margin-bottom: 1em;
        padding: 15px;
      }
    }
  }
}
</style>
