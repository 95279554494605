<template>
  <button @click="$emit('click')" class="ui-button">
    <slot></slot>
  </button>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ui-button {
  font-family: inherit;
  font-size: 1em;
  border: solid 1px;
  background: none;
  color: inherit;
  padding: 0.5em 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #fff;
    color: #000;
  }
  &.rounded {
    border-radius: 60px;
  }
  &.main {
    background: var(--main);
    border: none;
  }
}
</style>
