<template>
  <div class="ui-form">
    <form v-if="!sended" @submit.prevent="submit">
      <slot />
      <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown">
        <div v-if="phoneError" class="phoneError">
          {{ phoneError }}
        </div>
      </transition>
      <div class="agree-wrapper">
        <input v-model="agreed" required type="checkbox" />
        <div class="agree">Отправляя форму, вы соглашаетесь с <a href="/policy">политикой конфиденциальности</a></div>
      </div>
    </form>
    <div v-else class="app-form-success">
      <div class="pic animated zoomIn">
        <svg
          id="Layer_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M437.019,74.98C388.667,26.628,324.381,0,256,0S123.333,26.628,74.982,74.98C26.628,123.333,0,187.62,0,256
                s26.628,132.667,74.982,181.02C123.333,485.372,187.619,512,256,512s132.667-26.628,181.019-74.98
                C485.372,388.667,512,324.38,512,256S485.372,123.333,437.019,74.98z M256,491.602c-129.911,0-235.602-105.69-235.602-235.602
                S126.089,20.398,256,20.398S491.602,126.089,491.602,256S385.911,491.602,256,491.602z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M401.592,146.105c-13.918-13.918-36.565-13.918-50.483,0L200.924,296.289l-49.213-49.211
                c-13.918-13.918-36.565-13.918-50.483,0c-13.918,13.918-13.918,36.565,0,50.484l74.455,74.454
                c6.743,6.742,15.707,10.454,25.241,10.454s18.498-3.713,25.242-10.455l175.426-175.426
                C415.509,182.67,415.509,160.022,401.592,146.105z M387.168,182.164L211.743,357.59c-2.89,2.889-6.732,4.482-10.818,4.482
                s-7.928-1.592-10.817-4.482l-74.454-74.454c-5.966-5.966-5.966-15.671-0.001-21.636c2.982-2.982,6.9-4.473,10.818-4.473
                c3.917,0,7.836,1.492,10.817,4.474l56.424,56.424c3.985,3.983,10.441,3.983,14.425,0l157.395-157.397
                c5.966-5.965,15.672-5.965,21.637,0C393.133,166.493,393.133,176.198,387.168,182.164z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M462.131,298.133c-5.464-1.392-11.011,1.907-12.4,7.366C427.176,394.055,347.512,455.904,256,455.904
                c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199c100.847,0,188.64-68.166,213.498-165.769
                C470.888,305.076,467.591,299.523,462.131,298.133z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M466.104,245.802c-5.632,0-10.199,4.566-10.199,10.199c0,2.916-0.063,5.855-0.188,8.739
                c-0.244,5.627,4.121,10.387,9.749,10.63c0.15,0.006,0.299,0.009,0.448,0.009c5.43,0,9.945-4.282,10.182-9.759
                c0.138-3.175,0.207-6.411,0.207-9.619C476.303,250.368,471.736,245.802,466.104,245.802z"
              />
            </g>
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      </div>
      <div style="animation-delay: 0.3s" class="success-title animated fadeInUp goldDark">Спасибо!</div>
      <p style="animation-delay: 0.6s" class="animated fadeInUp">Мы свяжемся с Вами в ближайшее время</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      agreed: true,
      sended: false,
      phoneError: '',
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings
    },
    ui() {
      return this.$store.state.ui.ui
    },
  },
  methods: {
    submit($event) {
      const form = $event.target
      const valid = form.checkValidity()
      if (valid) {
        this.phoneError = ''
        const formData = new FormData(form)
        formData.append('form_subject', this.data ? this.data.subject : 'Без темы')
        formData.append(
          'admin_email',
          process.env.NODE_ENV === 'production' ? 'darker888@yandex.ru' : 'darker888@yandex.ru'
        )
        formData.append('project_name', 'Минивоз')
        for (const key in this.$route.query) {
          formData.append(key, this.$route.query[key])
        }
        this.sended = true
        axios.post(`https://lprf.biz/mail.php`, formData).then(() => {
          console.log('yes')
        })
        // this.settings.yandexMetricaID
        //   ? window[`yaCounter${this.settings.yandexMetricaID}`].reachGoal(
        //       'order'
        //     )
        //   : ''
        // window.gtag('event', 'order', { event_category: 'form' })
      } else {
        this.phoneError = 'Введите корректный телефон'
        setTimeout(() => {
          this.phoneError = ''
        }, 3000)
      }
    },
  },
}
</script>

<style lang="scss">
.app-form-success {
  .success-title {
    font-size: 2em;
    font-weight: 700;
    margin: 0.3em 0;
    & + p {
      color: var(--darkSubcolor);
    }
  }
  .pic {
    height: 5em;
    width: 5em;
    display: inline-block;
    margin-top: 1em;
    svg {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      fill: var(--main);
    }
  }
}
.agree {
  font-size: 0.675em;
  color: var(--subcolor);
  opacity: 0.8;
  a {
    text-decoration: underline;
    color: var(--main);
  }
}
.phoneError {
  color: rgb(197, 64, 64);
  font-weight: 600;
  background: rgb(197, 64, 64);
  color: #fff;
  padding: 0.5em 1em;
  font-size: 0.75em;
  border-radius: 10px;
  border-top-left-radius: 0;
  display: inline-block;
  margin-top: 1em;
}
.agree-wrapper {
  display: inline-flex;
  margin-top: 2em;
  margin-bottom: -1em;
  input {
    margin-right: 1em;
  }
}
</style>
