// Layout
import layoutPage from '@/components/layout/layout-page'
import layoutFooter from '@/components/layout/layout-footer'
import layoutPopup from '@/components/layout/layout-popup'
// App
import appGallery from '@/components/app/app-gallery'
import appReviews from '@/components/app/app-reviews'
import appStaff from '@/components/app/app-staff'
import appFeatures from '@/components/app/app-features'
import appWarranties from '@/components/app/app-warranties'
import appReqs from '@/components/app/app-reqs'
import appMiniGallery from '@/components/app/app-mini-gallery'
import appContacts from '@/components/app/app-contacts'
import appBlog from '@/components/app/app-blog'
import appBlogPost from '@/components/app/app-blog-post'
import appCalc from '@/components/app/app-calc'
import appServiceTransmit from '@/components/app/app-service-transmit'
import appServiceDelivery from '@/components/app/app-service-delivery'
import appServiceEv from '@/components/app/app-service-ev'
import appFor from '@/components/app/app-for'
import appExample from '@/components/app/app-example'
import appMain from '@/components/app/app-main'
// UI
import uiButton from '@/components/ui/ui-button'
// import uiForm from '@/components/ui/ui-form'
// import uiInput from '@/components/ui/ui-input'
import uiArrows from '@/components/ui/ui-arrows'
const components = (Vue) => {
  // Layout
  Vue.component('layoutPage', layoutPage)
  Vue.component('layoutFooter', layoutFooter)
  Vue.component('layoutPopup', layoutPopup)
  // App
  Vue.component('appGallery', appGallery)
  Vue.component('appReviews', appReviews)
  Vue.component('appStaff', appStaff)
  Vue.component('appFeatures', appFeatures)
  Vue.component('appWarranties', appWarranties)
  Vue.component('appReqs', appReqs)
  Vue.component('appMiniGallery', appMiniGallery)
  Vue.component('appContacts', appContacts)
  Vue.component('appBlog', appBlog)
  Vue.component('appBlogPost', appBlogPost)
  Vue.component('appCalc', appCalc)
  Vue.component('appServiceTransmit', appServiceTransmit)
  Vue.component('appServiceDelivery', appServiceDelivery)
  Vue.component('appServiceEv', appServiceEv)
  Vue.component('appFor', appFor)
  Vue.component('appExample', appExample)
  Vue.component('appMain', appMain)
  // UI
  Vue.component('uiButton', uiButton)
  // Vue.component('uiForm', uiForm)
  // Vue.component('uiInput', uiInput)
  Vue.component('uiArrows', uiArrows)
}
export default components
