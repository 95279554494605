<template>
  <div class="app-main">
    <div class="title-wrapper">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="subtitle"><slot name="subtitle"></slot></div>
    </div>
    <div class="offer"><slot name="offer"></slot></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-main {
  min-height: 72vh;
}
.title-wrapper {
  position: relative;
  display: inline-block;
}
.title {
  & > * {
    font-size: 3.8em;
    font-weight: 300;
    text-transform: uppercase;
  }
}
.subtitle {
  text-align: right;
}
.offer {
  margin-top: 5em;
  font-size: 2.5em;
  font-style: italic;
  font-weight: 300;
  font-family: 'din-c';
}
</style>
