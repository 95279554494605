<template>
  <div class="app-blog">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app-blog {
  .content {
    background: rgba(54, 54, 54, 0.2);
    border-radius: 10px;
    padding: 2em 2em;
    position: relative;
    &:not(:first-child) {
      img,
      .desc,
      .button {
        display: none;
      }
      .title {
        font-size: 0.875em;
      }
    }
    .date {
      padding-top: 8px;
      width: 90px;
      height: 50px;
      font-size: 15px;
      text-align: center;
      background: url("../../assets/img/date.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
    .date-title {
      display: flex;
      .title {
        font-weight: 600;
        text-transform: uppercase;
        padding-top: 0.5em;
      }
    }
    img {
      border-radius: 10px;
      margin-top: 1em;
    }
    .desc {
      font-size: 0.9375em;
      margin-top: 1em;
    }
  }
  .share {
    margin-top: 2em;
    border-top: solid 1px;
    padding-top: 1em;
    span {
      margin-bottom: 0.4em;
      display: block;
    }
  }
}
@media screen and (max-width: 767px) {
  .app-blog {
    .content {
      padding: 15px;
    }
  }
}
</style>
