<template>
  <div class="app-warranties">
    <slot name="title"></slot>
    <div class="features" :class="[{ fourCount }, { alignLeft }]">
      <div class="wrapper">
        <slot name="inner-title"></slot>
        <div class="features-items">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fourCount: {
      type: Boolean,
      default() {
        return false
      },
    },
    alignLeft: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.app-warranties {
  color: #fff;
  .middle-title {
    margin-bottom: 0.5em;
  }
}
.features {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  &.fourCount {
    .features-items {
      grid-template-columns: repeat(4, 1fr);
      gap: 2em;
      & > * {
        div {
          font-size: 0.75em;
        }
      }
    }
    .wrapper {
      padding: 2em;
    }
  }
  &.alignLeft {
    text-align: left;
  }
}
.wrapper {
  position: relative;
  padding: 3em;
}
.features-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3em;
  & > * {
    div {
      font-size: 0.875em;
      margin-top: 1em;
    }
  }
}
@media screen and (max-width: 767px) {
  .features-items {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
</style>
