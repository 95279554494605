<template>
  <div class="app-service-ev">
    <div class="gallery">
      <div class="title-image">
        <slot name="desc"></slot>
        <slot name="image"></slot>
      </div>
      <div class="services">
        <slot></slot>
      </div>
    </div>
    <!--  -->
    <slot name="howto-title"></slot>
    <div class="gallery howto-wrapper">
      <div class="items">
        <slot name="howto"></slot>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      length: 0,
      current: 0,
    }
  },
  mounted() {
    const length = this.$el.querySelectorAll('.images > *').length
    this.length = length
    this.setActive()
  },
  methods: {
    setActive(index = 0) {
      const images = [...this.$el.querySelectorAll('.mini-items > *')]
      images.forEach((image, i) => {
        if (i !== index) {
          image.classList.remove('active')
        } else {
          image.classList.add('active')
        }
      })
    },
    next() {
      if (this.current !== this.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
      this.setActive(this.current)
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.length - 1
      }
      this.setActive(this.current)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-service-ev {
  color: #fff;
}
.gallery {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  padding: 1em;
  margin-bottom: 2em;
  .gallery {
  }
}
.wrapper {
  position: relative;
}
.images {
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  transition: 0.3s;
  & > * {
    position: relative;
    padding-bottom: 70%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.title-image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5em;
  .desc {
    font-size: 0.9375em;
  }
}
.services {
  margin-top: 1em;
  & > * {
    display: grid;
    grid-template-columns: 10em 1fr;
    gap: 2em;
    margin-bottom: 1em;
    & > div {
      &:nth-child(1) {
        background: rgba(54, 54, 54, 0.2);
        border-radius: 10px;
        padding: 0.3em;
        text-align: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background: rgba(54, 54, 54, 0.5);
        }
      }
      span {
        display: block;
        &:nth-child(1) {
          margin-bottom: 0.5em;
        }
      }
    }
    // & > div {
    //   background: rgba(54, 54, 54, 0.2);
    //   border-radius: 10px;
    //   padding: 0.3em;
    //   text-align: center;
    //   flex-grow: 1;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }
    // img {
    //   margin-bottom: 1em;
    //   height: 4em;
    // }
    // span {
    //   display: block;
    //   margin-top: auto;
    // }
    button {
      font-size: 1em;
      height: 30px;
      font-family: inherit;
      background: none;
      border-radius: 5px;
      // border: solid 1px;
      text-transform: uppercase;
      color: #fff;
      width: 100%;
      margin-top: 1em;
    }
  }
}
.howto-wrapper {
  margin-top: 1em;
}
.items {
  display: flex;
  & > * {
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}
@media screen and (max-width: 767px) {
  .gallery {
    padding: 15px;
  }
  .buttons {
    & > * {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
}
</style>
