import Vue from "@/index.js";

import page from "@/mixins/page";

export default new Vue({
  el: "#page",
  components: {},
  mixins: [page],
  methods: {},
});
