<template>
  <transition leave-active-class="animated fadeOut">
    <div v-if="opened" class="email-popup layout-popup abs animated fadeIn">
      <div class="popup-overlay abs animated fadeIn" @click="close" />
      <div class="window shadow-card animated zoomIn">
        <div class="close" @click="close"></div>
        <div class="window-content">
          <div class="title animated fadeInUp color-grad">
            {{ data.title || 'Оставьте заявку' }}
          </div>
          <div v-if="data.subtitle" class="subtitle reg-text">
            {{ data.subtitle }}
          </div>
          <ui-form
            :data="data"
            class="animated zoomIn"
            style="animation-delay: 0.3s"
            @startLoad="loading = true"
            @stopLoad="loading = false"
          >
            <ui-input
              v-model="inputs.name"
              name="name"
              type="text"
              required
              placeholder="Введите Ваше имя"
              label="Имя"
            />
            <ui-input
              v-model="inputs.phone"
              name="phone"
              type="phone"
              required
              placeholder="+7 (___) ___-__-__"
              label="Телефон"
            />
            <ui-input
              v-model="inputs.email"
              name="email"
              type="email"
              required
              placeholder="Введите email"
              label="Email"
            />
            <ui-input
              v-for="(field, index) in data.fields"
              :key="index"
              v-model="inputs[field.name]"
              :type="field.type"
              :name="field.name"
              :required="field.required"
              :placeholder="field.placeholder"
              :label="field.label"
            />
            <div>
              <ui-button v-if="!loading" class="blue" dark>
                {{ data.button }}
              </ui-button>
            </div>
          </ui-form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import uiForm from '@/components/ui/ui-form'
import uiInput from '@/components/ui/ui-input'
export default {
  components: {
    uiForm,
    uiInput,
  },
  data() {
    return {
      opened: false,
      data: {},
      loading: false,
      inputs: {},
    }
  },
  created() {
    this.$bus.$on('popup', (data = {}) => {
      this.inputs = {}
      this.opened = true
      this.data = data
      if (data.fields) {
        for (const field of data.fields) {
          this.inputs[field.name] = field.value || ''
        }
      }
    })
  },
  methods: {
    close() {
      this.opened = false
    },
  },
}
</script>

<style lang="scss">
.layout-popup {
  position: fixed !important;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  .window {
    width: 35em;
    background: rgb(49, 144, 221);
    // box-shadow: 0px 54px 74px #e9ecf2;
    position: relative;
    padding: 5em;
    border-radius: 20px;
    text-align: center;
    .window-content {
      width: 100%;
    }
    .subtitle {
      color: var(--main);
    }
    .close {
      width: 2em;
      height: 2em;
      position: absolute;
      right: 2.5em;
      top: 2.5em;
      cursor: pointer;
      opacity: 0.4;
      transition: 0.3s;
      border-radius: 50%;
      &:hover {
        opacity: 1;
      }
      background: url('../../assets/img/icons/close.svg'), rgba(0, 0, 0, 0.2);
      background-size: 40%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .title {
      margin-bottom: 1em;
      font-size: 3em;
    }
    .subtitle {
      margin-bottom: 4em;
      margin-top: -3em;
    }
    .ui-button {
      margin-top: 4em;
    }
    .ui-input {
      margin-bottom: 1em;
    }
    .icon {
      width: 100%;
      height: 5em;
      position: relative;
      margin: auto;
      margin-bottom: 2em;
      .bg {
        background-size: contain !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
      }
    }
  }
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  // backdrop-filter: blur(20px);
}
</style>
