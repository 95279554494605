<template>
  <div class="app-reqs">
    <div class="features">
      <slot name="title"></slot>
      <div class="data">
        <slot></slot>
      </div>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app-reqs {
  color: #fff;
  .middle-title {
    margin-bottom: 0.5em;
  }
}
.features {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  overflow: hidden;
  padding: 1.5em;
  text-align: center;
}
.wrapper {
  position: relative;
}
.data {
  border-top: dashed 2px #fff;
  margin-top: 1em;
  padding-top: 1.5em;
  margin-bottom: 1em;
  & > * {
    margin-bottom: 1.5em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    & > * {
      &:nth-child(1) {
        text-align: right;
      }
      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}
</style>
