<template>
  <div class="layout-footer">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-3 col-xs-6 text-left">
          <div class="menu">
            <slot name="menu"></slot>
          </div>
        </div>
        <!--  -->
        <div class="col-4 col-xs-6 text-left">
          <div class="phone"><slot name="phone"></slot></div>
          <div class="address"><slot name="address"></slot></div>
          <div class="email"><slot name="email"></slot></div>
          <div class="error"><slot name="error"></slot></div>
        </div>
        <div class="col-2 col-xs-6 text-left">
          <div class="socials">
            <slot name="socials"></slot>
          </div>
          <div class="soc-name">Наши соц.сети</div>
          <div class="copyright">
            <slot name="copyright"></slot>
          </div>
        </div>
        <!--  -->
        <div class="col-3 col-xs-6">
          <a href="/" class="logo"><img src="../../assets/img/big-logo.png" alt="" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-footer {
  background: #4a789c;
  padding: 1em 0;
  margin-top: 4em;
}
.menu {
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1em;
  }
  li {
    list-style: none;
    margin: 0.5em 0;
  }
  a {
    font-size: 1.25em;
    text-decoration: underline;
    font-weight: 300;
  }
}
.phone {
  font-size: 1.5em;
  font-weight: 700;
  margin-top: 0.3em;
}
.email {
  margin-top: 1em;
  font-weight: 600;
  font-size: 1.125em;
  font-family: Arial, Helvetica, sans-serif;
}
.error {
  font-size: 0.875em;
  color: #000;
  opacity: 0.7;
  margin-top: 2em;
}
.socials {
  padding-top: 0.3em;
  a {
    margin-right: 1em;
  }
  img {
    width: 3em;
    height: 3em;
  }
}
.soc-name {
  margin-top: 1em;
}
.copyright {
  img {
    height: 1em;
    margin-top: 2em;
  }
}
@media screen and (max-width: 767px) {
  .phone {
    font-size: 1.2em;
  }
  .logo {
    width: 8em;
  }
}
</style>
