<template>
  <div class="app-contacts">
    <div class="content">
      <slot></slot>
      <!--  -->
      <div class="map-content">
        <div class="side">
          <slot name="address"></slot>
          <div @click="showMap" v-if="src" class="button value">Показать на карте</div>
          <slot name="phone"></slot>
          <slot name="email"></slot>
        </div>
        <div v-if="!src" class="map">
          <slot name="map"></slot>
        </div>
        <div class="map" v-else>
          <img :src="src" class="abs" alt="" />
        </div>
      </div>
      <!--  -->
      <slot name="gallery"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      current: 0,
      src: '',
    }
  },
  mounted() {
    this.items = [...this.$el.querySelectorAll('.gallery >*')]
    this.items.forEach((item) => {
      item.onclick = this.setActive
    })
  },
  methods: {
    showMap() {
      this.items.forEach((item) => {
        item.classList.remove('active')
      })
      this.src = ''
    },
    setActive($event) {
      for (const item of this.items) {
        if (item !== $event.target) {
          item.classList.remove('active')
        } else {
          item.classList.add('active')
          const img = item.querySelector('img')
          this.src = img.src
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-contacts {
  .label {
    font-size: 1.25em;
    font-weight: 600;
  }
  .value {
    font-size: 1.25em;
    margin-top: 0.3em;
  }
  .address {
    &.main {
      padding-bottom: 1em;
      border-bottom: dashed 2px;
    }
  }
  .phone,
  .email {
    margin-top: 2em;
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 1em;
    & > * {
      position: relative;
      padding-bottom: 70%;
      overflow: hidden;
      &.active {
        &::after {
          opacity: 0;
        }
      }
      &:after {
        background: #2f9feb;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        content: '';
        mix-blend-mode: multiply;
        transition: 0.3s;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.content {
  background: rgba(54, 54, 54, 0.2);
  border-radius: 10px;
  padding: 2em 2em;
}
.map-content {
  padding-top: 1em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}
.map {
  background: #fff;
  padding-bottom: 100%;
  position: relative;
  img {
    object-fit: cover;
  }
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.button {
  text-decoration: underline;
  color: #ff8a00;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .content {
    padding: 15px;
  }
  .map-content {
    display: block;
  }
  .map {
    margin-top: 2em;
  }
}
</style>
