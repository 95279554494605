<template>
  <div class="ui-input" :class="[{ active: value }]">
    <label>{{ label }}</label>
    <textarea
      v-if="type === 'textarea'"
      :name="name"
      :value="value"
      :required="required"
      :placeholder="placeholder"
      :type="type"
      rows="3"
      @input="input"
    ></textarea>
    <input
      v-else
      :placeholder="placeholder"
      :name="name"
      :value="value"
      :required="required"
      :type="type"
      @input="input"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default() {
        return 'text'
      },
    },
    name: {
      type: String,
      default() {
        return ''
      },
    },
    label: {
      type: String,
      default() {
        return ''
      },
    },
    placeholder: {
      type: String,
      default() {
        return ''
      },
    },
    value: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    required: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  mounted() {
    if (this.type === 'phone') {
      const Inputmask = require('inputmask').default
      const input = this.$el.querySelector('input')
      const im = new Inputmask({
        mask: '+7 (999) 999-999',
        showMaskOnHover: false,
        showMaskOnFocus: true,
      })
      im.mask(input)
    }
  },
  methods: {
    input($event) {
      this.$emit('input', $event.target.value)
    },
    onChange({ value }) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-input {
  position: relative;
  &.active,
  &:focus-within {
    input,
    textarea {
      color: #fff;
      padding: 1.4em 1em 0.6em;
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
      &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
      &:-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    label {
      font-size: 0.7em;
      top: -0.5em;
      left: 0.5em;
    }
  }
}
input,
textarea,
label {
  resize: none;
  width: 100%;
  font-family: inherit;
  font-size: 1em;
  color: #fff;
  text-align: inherit;
  font-weight: 600;
  padding: 1em;
  border-radius: 10px;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.05);
  &::-webkit-input-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
    color: transparent;
  }
  &:-moz-placeholder {
    color: transparent;
  }
  &:-ms-input-placeholder {
    color: transparent;
  }
}
label {
  position: absolute;
  pointer-events: none;
  background: none;
}
</style>
